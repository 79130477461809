import React, { useState } from "react";
import { css } from "@emotion/core";
import { graphql } from "gatsby";
import Link from "../../components/library/Link";
import { animated } from "react-spring";

import { useFade } from "../../transitions";
import Card from "../../components/library/Card";
import TextInput from "../../components/library/TextInput";
import TextArea from "../../components/library/TextArea";
import Form from "../../components/library/Form";
import { primaryColorDark, mq } from "../../theme";

const puppyCardContainerStyles = css`
  margin: auto;
  max-width: 70em;
  padding: 0 24px;
`;

const puppiesListStyles = css`
  margin-bottom: 16px;

  ${mq("medium")} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const puppyCardStyles = css`
  ${mq("medium")} {
    max-width: 300px;
    margin: 16px;
    margin-bottom: auto;
  }
`;

const cardDetails = css`
  display: flex;
  flex-wrap: wrap;
`;

const cardDetailItem = css`
  width: 50%;
  margin-bottom: 0;
`;

const descriptionStyles = css`
  margin-top: 16px;

  color: ${primaryColorDark};
  font-size: 16px;
`;

const viewButtonStyles = css`
  width: 96px;
  margin-left: auto;
`;

const noPuppiesContainerStyles = css`
  max-width: 600px;
  margin: auto;
  padding: 0 12px;
`;

export default function Puppies({ data, transitionStatus }) {
  const fade = useFade(transitionStatus);
  const puppies = data.puppies.nodes.filter(
    (puppy) => !puppy.data.frontmatter.hidden
  );
  const [personName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comments, setComments] = useState("");

  return (
    <animated.div style={fade}>
      {puppies.length ? (
        <div css={puppyCardContainerStyles}>
          <h1>Available Puppies</h1>
          <div css={puppiesListStyles}>
            {puppies.map((puppy, i) => {
              const pup = puppy.data;

              return (
                <Card
                  key={i}
                  header={pup.frontmatter.name}
                  hero={pup.frontmatter.coverImage.childImageSharp.fluid}
                  style={puppyCardStyles}
                >
                  <div css={cardDetails}>
                    {pup.frontmatter.details &&
                      pup.frontmatter.details.map((dt, j) => {
                        return (
                          <dl css={cardDetailItem} key={j}>
                            <dt>{dt.name}</dt>
                            <dd>{dt.value}</dd>
                          </dl>
                        );
                      })}
                  </div>
                  <p css={descriptionStyles}>{pup.excerpt}</p>
                  <Link
                    css={viewButtonStyles}
                    className="button"
                    to={pup.fields.slug}
                  >
                    More...
                  </Link>
                </Card>
              );
            })}
          </div>
        </div>
      ) : (
        <div css={noPuppiesContainerStyles}>
          <h2
            css={css`
              margin-bottom: 8px;
            `}
            className="tac"
          >
            Looks like they're gone!
          </h2>
          <p className="tac mb0 mt0 light">
            If you'd like to be notified when we have more available, fill out
            the form below, and you'll be amongst the first to know.
          </p>
          <Form
            name="puppy-waiting-list-form"
            completedEl={
              <>
                <h2>Thanks for your enquiry</h2>
                <h3>We'll let you know when we have more available</h3>
              </>
            }
          >
            {({ submitting, submitted }) => (
              <div css={{ paddingTop: 24 }}>
                <TextInput
                  name="personName"
                  label="Name*"
                  required
                  disabled={submitting || submitted}
                  onChange={(e) => setName(e.target.value)}
                  value={personName}
                />
                <TextInput
                  name="email"
                  label="Email"
                  disabled={submitting || submitted}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <TextInput
                  name="personPhone"
                  label="Phone"
                  disabled={submitting || submitted}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
                <TextArea
                  required
                  name="personComments"
                  label="Comments/Questions*"
                  disabled={submitting || submitted}
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                />

                <button
                  disabled={submitting || submitted}
                  css={{
                    margin: "auto",
                  }}
                  className="primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            )}
          </Form>
        </div>
      )}
    </animated.div>
  );
}

export const query = graphql`
  query PuppiesQuery {
    puppies: allFile(
      filter: {
        internal: { mediaType: { eq: "text/markdown" } }
        sourceInstanceName: { eq: "pages" }
        relativeDirectory: { eq: "puppies" }
      }
    ) {
      nodes {
        data: childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            hidden
            name
            coverImage {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            details {
              name
              value
            }
            description
          }
          html
          excerpt
        }
      }
    }
  }
`;
