import React from "react";
import { css } from "@emotion/core";
import { PrimaryColorLight, primaryColor } from "../../theme";

const containerStyles = css`
  display: block;
  position: relative;

  height: 48px;

  flex-grow: 1;
  margin: 12px;
  margin-top: 20px;

  font-size: 16px;

  border-bottom: 1px solid ${PrimaryColorLight};

  & > textarea {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;

    background-color: transparent;
    border: none;
    box-shadow: none;

    color: ${primaryColor};
    font-size: 16px;

    & + .label {
      display: inline-block;
      pointer-events: none;
      transform-origin: left center;
      transition-duration: 0.3s;
    }

    &:focus + .label,
    &:active + .label,
    &.filled + .label {
      color: ${PrimaryColorLight};
      transform: translate(0, -80%) scale(0.8);
    }
  }
`;

export default function TextArea({ label, value, ...rest }) {
  return (
    <label css={containerStyles}>
      <textarea
        type="text"
        className={`${value ? "filled" : ""}`}
        value={value}
        {...rest}
      />
      <span className="label">{label}</span>
    </label>
  );
}
