import React, { useState, useRef } from "react";
import { css, keyframes } from "@emotion/core";

import pawImage from "../../../static/img/icons/paw-black.svg";

const pulseKeyframes = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.9;
    }
    100% {
      transform: scale(0.8);
      opacity: 0.9;
    }
`;

const formStyles = css`
  position: relative;

  &:after {
    content: "";
    display: none;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: url(${pawImage});
    background-position: center;
    background-size: auto 25%;
    background-repeat: no-repeat;

    animation: ${pulseKeyframes} 2s linear infinite alternate;
  }

  &.pending {
    opacity: 0.5;
    pointer-events: none;

    &:after {
      display: block;
    }
  }

  .sent-confirmation {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    text-align: center;

    pointer-events: none;

    transform: translate(0, 50px);
    opacity: 0;
    transition-duration: 0.3s;

    & > h2 {
      margin-bottom: 0;
    }
  }

  &.sent > .sent-confirmation {
    transform: translate(0);
    opacity: 0.9;
  }
`;

const Form = ({ children, name: formName, completedEl }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formEl = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = formEl.current.action;

    const formPrefix = `form-name=${encodeURIComponent(formEl.current.name)}`;
    const formData = new URLSearchParams(
      Array.from(new FormData(formEl.current))
    ).toString();

    const formPayload = formPrefix + "&" + formData;

    setFormSubmitting(true);

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formPayload,
    }).then(() => {
      setFormSubmitting(false);
      setFormSubmitted(true);
    });
  };

  return (
    <form
      css={formStyles}
      className={`${formSubmitting ? "pending" : ""} ${
        formSubmitted ? "sent" : ""
      }`}
      data-netlify="true"
      ref={formEl}
      onSubmit={handleSubmit}
      name={formName}
      netlify-honeypot="hpot"
    >
      <div className="hpot">
        <input name="hpot" />
      </div>
      {children({
        submitting: formSubmitting,
        submitted: formSubmitted,
      })}

      <div className="sent-confirmation">{completedEl}</div>
    </form>
  );
};

export default Form;
